import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VDialog, {
    attrs: {
      "max-width": "700px"
    },
    model: {
      value: _vm.youSure,
      callback: function ($$v) {
        _vm.youSure = $$v;
      },
      expression: "youSure"
    }
  }, [_c(VCard, [_c(VCardTitle, {
    staticClass: "headline justify-center py-8"
  }, [_vm._v(_vm._s(this.promptText))]), _c(VCardActions, {
    staticClass: "pb-5 pr-5"
  }, [_c(VSpacer), _c('div', [_c(VBtn, {
    staticClass: "white--text",
    attrs: {
      "color": "red darken-1"
    },
    on: {
      "click": _vm.actionPerformed
    }
  }, [_vm._v(_vm._s(this.actionOption))]), _c(VBtn, {
    attrs: {
      "color": "black",
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.youSure = false;
      }
    }
  }, [_vm._v("Abbrechen")])], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };