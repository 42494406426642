import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VDialog, {
    attrs: {
      "scrollable": "",
      "max-width": "1000px",
      "persistent": ""
    },
    model: {
      value: _vm.appointmentHistory,
      callback: function ($$v) {
        _vm.appointmentHistory = $$v;
      },
      expression: "appointmentHistory"
    }
  }, [_c(VCard, [_c(VCardTitle, {
    staticClass: "mx-0 px-0 my-0 py-0"
  }, [_c(VToolbar, {
    staticClass: "mx-0 px-0 my-0 py-0",
    attrs: {
      "color": "primary",
      "fixed": "",
      "top": "",
      "dark": "",
      "flat": ""
    }
  }, [_c(VIcon, {
    on: {
      "click": function ($event) {
        _vm.appointmentHistory = false;
      }
    }
  }, [_vm._v("mdi-close")]), _c(VToolbarTitle, {
    staticClass: "flex text-center"
  }, [_vm._v("Terminverlauf")])], 1)], 1), _c(VCardText, [_c(VTimeline, _vm._l(_vm.history, function (entry) {
    return _c(VTimelineItem, {
      key: entry.appointment.mdat
    }, [_c(VCard, {
      staticStyle: {
        "border": "1px #e0e0e0 solid"
      },
      attrs: {
        "elevation": "0"
      }
    }, [_c(VCardTitle, [_vm._v(" " + _vm._s(_vm.dateTimeService.getGermanDateFormat(entry.appointment.mdat)))]), _c(VCardSubtitle, [_vm._v(_vm._s(entry.appointment.muser))]), _vm._l(entry.changes, function (change) {
      return _c(VCardText, {
        key: change
      }, [_c('b', [_vm._v(_vm._s(_vm.loadGermanPropertyName(Object.keys(change)[0])) + ":")]), _c('br'), _vm._v(" " + _vm._s(_vm.loadValue(change, 0)) + " "), _c(VIcon, [_vm._v("mdi-chevron-double-right")]), _vm._v(" " + _vm._s(_vm.loadValue(change, 1)) + " ")], 1);
    })], 2)], 1);
  }), 1)], 1), _c(VCardActions, [_c(VSpacer), _c(VBtn, {
    staticClass: "primary",
    attrs: {
      "elevation": "0"
    },
    on: {
      "click": function ($event) {
        _vm.appointmentHistory = false;
      }
    }
  }, [_vm._v(" OK ")])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };