import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VDialog, {
    attrs: {
      "scrollable": "",
      "max-width": "1000px",
      "persistent": ""
    },
    model: {
      value: _vm.appointmentFilter,
      callback: function ($$v) {
        _vm.appointmentFilter = $$v;
      },
      expression: "appointmentFilter"
    }
  }, [_c(VCard, [_c(VCardTitle, {
    staticClass: "mx-0 px-0 my-0 py-0"
  }, [_c(VToolbar, {
    staticClass: "mx-0 px-0 my-0 py-0",
    attrs: {
      "color": "primary",
      "fixed": "",
      "top": "",
      "dark": "",
      "flat": ""
    }
  }, [_c(VIcon, {
    on: {
      "click": function ($event) {
        _vm.appointmentFilter = false;
      }
    }
  }, [_vm._v("mdi-close")]), _c(VToolbarTitle, {
    staticClass: "flex text-center"
  }, [_vm._v("Termine filtern")])], 1)], 1), _c(VCardText, [_c(VContainer, {
    staticClass: "mt-4 mb-4",
    attrs: {
      "justify": "center"
    }
  }, [_c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c(VSelect, {
    staticClass: "mx-sm-1",
    attrs: {
      "prepend-inner-icon": "mdi-calendar-check",
      "outlined": "",
      "label": "Status",
      "items": _vm.states,
      "item-text": "description",
      "item-value": item => item,
      "chips": "",
      "small-chips": "",
      "deletable-chips": "",
      "multiple": ""
    },
    model: {
      value: _vm.filter.states,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "states", $$v);
      },
      expression: "filter.states"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c(VSelect, {
    staticClass: "mx-sm-1",
    attrs: {
      "prepend-inner-icon": "mdi-calendar-weekend-outline",
      "outlined": "",
      "label": "Bereich",
      "items": _vm.scopes,
      "item-text": "name",
      "item-value": item => item,
      "chips": "",
      "small-chips": "",
      "deletable-chips": "",
      "multiple": ""
    },
    model: {
      value: _vm.filter.scopes,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "scopes", $$v);
      },
      expression: "filter.scopes"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c(VSelect, {
    staticClass: "mx-sm-1",
    attrs: {
      "prepend-inner-icon": "mdi-calendar-star-four-points",
      "outlined": "",
      "label": "Terminart",
      "items": _vm.types,
      "item-text": "name",
      "item-value": item => item,
      "chips": "",
      "small-chips": "",
      "deletable-chips": "",
      "multiple": ""
    },
    model: {
      value: _vm.filter.types,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "types", $$v);
      },
      expression: "filter.types"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c(VSelect, {
    staticClass: "mx-sm-1",
    attrs: {
      "prepend-inner-icon": "mdi-map-marker",
      "outlined": "",
      "label": "Lokation",
      "items": _vm.locations,
      "item-text": "name",
      "item-value": item => item,
      "chips": "",
      "small-chips": "",
      "deletable-chips": "",
      "multiple": ""
    },
    model: {
      value: _vm.filter.locations,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "locations", $$v);
      },
      expression: "filter.locations"
    }
  })], 1)], 1)], 1)], 1), _c(VCardActions, {
    staticClass: "d-flex justify-end"
  }, [_c(VBtn, {
    staticClass: "primary",
    attrs: {
      "elevation": "0"
    },
    on: {
      "click": function ($event) {
        return _vm.applyFilter();
      }
    }
  }, [_vm._v(" Anwenden ")])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };