import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VDialog, {
    attrs: {
      "transition": "dialog-bottom-transition",
      "max-width": "600",
      "scrollable": ""
    },
    model: {
      value: _vm.quickUpload,
      callback: function ($$v) {
        _vm.quickUpload = $$v;
      },
      expression: "quickUpload"
    }
  }, [_c(VCard, [_c(VCardTitle, {
    staticClass: "mx-0 px-0 my-0 py-0"
  }, [_c(VToolbar, {
    attrs: {
      "fixed": "",
      "top": "",
      "flat": ""
    }
  }, [_c(VIcon, {
    on: {
      "click": function ($event) {
        _vm.quickUpload = false;
      }
    }
  }, [_vm._v("mdi-close")]), _c(VToolbarTitle, {
    staticClass: "flex text-center"
  }, [_vm._v(" Bilderupload - Adressenwahl ")])], 1)], 1), _c(VCardText, {
    staticClass: "pt-5 pl-10"
  }, [_vm.appointment.addresses == null || _vm.appointment.addresses.length == 0 ? _c('p', [_vm._v(" Dem Termin sind keine Adressen zugeordnet. ")]) : _c('p', [_vm._v(" Bitte wählen Sie eine Adresse zur Zuordnung: ")]), _c(VSelect, {
    attrs: {
      "items": _vm.appointment.addresses,
      "disabled": _vm.appointment.addresses == null || _vm.appointment.addresses.length == 0,
      "item-text": "name",
      "item-value": "name",
      "return-object": ""
    },
    model: {
      value: _vm.selectedAddress,
      callback: function ($$v) {
        _vm.selectedAddress = $$v;
      },
      expression: "selectedAddress"
    }
  })], 1), _c(VCardActions, [_c(VBtn, _vm._b({
    staticClass: "white--text",
    attrs: {
      "color": "green darken-1",
      "disabled": _vm.selectedAddress == null
    },
    on: {
      "click": function ($event) {
        _vm.showAddressPicturesDialog = true;
      }
    }
  }, 'v-btn', _vm.attrs, false), [_vm._v("OK")]), _c(VBtn, {
    attrs: {
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.quickUpload = false;
      }
    }
  }, [_vm._v("Abbrechen")])], 1)], 1), _vm.showAddressPicturesDialog ? _c('AddressPicturesDialog', {
    attrs: {
      "address": _vm.selectedAddress,
      "opened": true
    },
    on: {
      "close-dialog": function ($event) {
        return _vm.closeAddressPicturesDialog();
      }
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };