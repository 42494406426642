import { VCalendar } from 'vuetify/lib/components/VCalendar';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VSheet, {
    attrs: {
      "id": "calendarOverview",
      "height": _vm.calendarHeight
    }
  }, [_c(VCalendar, {
    ref: "calendarOverview",
    attrs: {
      "events": _vm.appointmentPreviews,
      "color": "primary",
      "event-ripple": false,
      "interval-height": _vm.intervalHeight,
      "event-margin-bottom": parseInt('2'),
      "event-overlap-mode": "column",
      "type": _vm.typeOfCalendarOverview,
      "category-show-all": "",
      "categories": _vm.categorieTypes,
      "category-text": _vm.categoryTextValue,
      "event-category": _vm.getEventCategory,
      "weekdays": _vm.weekdays,
      "show-week": true,
      "event-text-color": _vm.getEventTextColor
    },
    on: {
      "change": _vm.updateRange,
      "click:event": _vm.showAppointmentContexMenu,
      "click:more": _vm.viewDay,
      "click:date": _vm.viewDay,
      "click:time": _vm.clickTime,
      "click:time-category": _vm.clickCategoryTime,
      "mousedown:event": _vm.startDrag,
      "mousedown:time": _vm.startTime,
      "mousemove:time": _vm.mouseMove,
      "mousemove:time-category": _vm.mouseMoveCategory,
      "mouseup:time": _vm.endDrag
    },
    nativeOn: {
      "mouseleave": function ($event) {
        return _vm.cancelDrag.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "event",
      fn: function ({
        event
      }) {
        return [_c('span', {
          style: {
            color: _vm.getEventTextColor(event)
          }
        }, [event.state.key.lfdnr === 100 ? _c('span', {
          staticClass: "no-text-select overflow-hidden mb-1 mr-2"
        }, [_c(VIcon, {
          attrs: {
            "color": "white",
            "small": ""
          }
        }, [_vm._v("mdi-check")])], 1) : _vm._e(), _c('span', {
          staticClass: "no-text-select overflow-hidden"
        }, [_vm.eventTitle === 'scope' ? [_vm._v(" " + _vm._s(event.scope.name))] : _vm._e(), _vm.eventTitle === 'duration' ? [_vm.dateTimeService.getDurationSplit(event.duration).days !== 0 ? [_vm._v(_vm._s(_vm.dateTimeService.getDurationSplit(event.duration).days) + " Tage ")] : _vm._e(), _vm.dateTimeService.getDurationSplit(event.duration).hours !== 0 ? [_vm._v(_vm._s(_vm.dateTimeService.getDurationSplit(event.duration).hours) + " h ")] : _vm._e(), _vm._v(" " + _vm._s(_vm.dateTimeService.getDurationSplit(event.duration).minutes) + " min")] : _vm._e(), _vm.eventTitle === 'title' ? [_vm._v(" " + _vm._s(event.title) + " ")] : _vm._e(), _vm.eventTitle === 'location' ? [_vm._v(" " + _vm._s(event.location.name) + " ")] : _vm._e(), _vm.eventTitle === 'state' ? [_vm._v(" " + _vm._s(event.state.description) + " ")] : _vm._e(), _vm.eventTitle === 'beginAndEnd' ? [_vm._v(" " + _vm._s(_vm.dateTimeService.getGermanDateFormat(event.start)) + " - "), _c('br'), _vm._v(" " + _vm._s(_vm.dateTimeService.getGermanDateFormat(event.end)) + " ")] : _vm._e(), _vm.eventTitle === 'address' ? [_vm._v(" " + _vm._s(event.firstAddressKdSortname) + " ")] : _vm._e(), _vm.eventTitle === 'type' ? [_vm._v(" " + _vm._s(event.type.name) + " ")] : _vm._e(), [_c('br'), _vm._v(" " + _vm._s(_vm.dateTimeService.getTimeStringFromDateTimeString(event.start)) + " - " + _vm._s(_vm.dateTimeService.getTimeStringFromDateTimeString(event.end)) + " ")]], 2)])];
      }
    }, {
      key: "day-body",
      fn: function ({
        date,
        week
      }) {
        return [_c('div', {
          staticClass: "v-current-time",
          class: {
            first: date === week[0].date
          },
          style: {
            top: _vm.nowY
          },
          attrs: {
            "id": "v-current-time"
          }
        })];
      }
    }]),
    model: {
      value: _vm.focus,
      callback: function ($$v) {
        _vm.focus = $$v;
      },
      expression: "focus"
    }
  }, [_vm._v(" > ")]), _c('AppointmentPreviewContextMenu', {
    ref: "contextMenu",
    on: {
      "editAppointment": _vm.editAppointment,
      "deleteAppointment": _vm.deleteAppointment,
      "openAppointmentHistory": _vm.openAppointmentHistory,
      "quickOpenPictureUpload": _vm.quickOpenPictureUpload,
      "tryDeleteAppointment": _vm.tryDeleteAppointment
    }
  }), _c('moveAppointmentDialog', {
    ref: "moveAppointmentDialog",
    on: {
      "undo": _vm.undo,
      "save": _vm.save
    }
  }), _c('ErrorDialog', {
    attrs: {
      "failMessage": 'Kein Terminverlauf vorhanden',
      "opened": _vm.noAppointmentHistory
    },
    on: {
      "closeDialog": function ($event) {
        _vm.noAppointmentHistory = false;
      }
    }
  }), _c('Loading', {
    ref: "loading"
  }), _c('AppointmentHistory', {
    ref: "history"
  }), _c('QuickUploadAddressPictureDialog', {
    ref: "quickUpload"
  }), _c('WarningDialog', {
    ref: "warningLocked",
    on: {
      "warningActionPerformed": _vm.unlockAppointment
    }
  }), _c('WarningDialog', {
    ref: "warning",
    on: {
      "warningActionPerformed": _vm.triedDeleteAppointment
    }
  }), _c('WarningDialog', {
    ref: "warningSerial",
    on: {
      "warningActionPerformed": _vm.isSerialToDelete
    }
  }), _c('ReservedDataDialog', {
    ref: "reserved",
    on: {
      "editWithOption": _vm.saveReserved,
      "closeParent": _vm.closeReservedDataDialog
    }
  }), _c('SerialReservedDataDialog', {
    ref: "serialResDataDialog",
    on: {
      "close": _vm.closeSerialReservedDataDialog
    }
  }), _c('ForceUpdateDialog', {
    ref: "forceDialog",
    on: {
      "save": _vm.forced,
      "close": _vm.undo
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };