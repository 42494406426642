import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VDialog, {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "max-width": "600px"
    },
    model: {
      value: _vm.moveAppointmentModal,
      callback: function ($$v) {
        _vm.moveAppointmentModal = $$v;
      },
      expression: "moveAppointmentModal"
    }
  }, [_c(VCard, [_c(VCardTitle, {
    staticClass: "mx-0 px-0 my-0 py-0"
  }, [_c(VToolbar, {
    staticClass: "mx-0 px-0 my-0 py-0",
    attrs: {
      "color": "primary",
      "fixed": "",
      "top": "",
      "dark": "",
      "flat": ""
    }
  }, [_c(VToolbarTitle, {
    staticClass: "flex text-center"
  }, [_vm._v("Termin verschieben")])], 1)], 1), _c(VCardText, {
    staticClass: "mx-0 px-0 my-3 py-0",
    attrs: {
      "justify-center": ""
    }
  }, [[_c('div', {
    attrs: {
      "align": "center"
    }
  }, [_c('span', {
    staticClass: "text-h4"
  }, [_vm._v(_vm._s(this.appointment.title))])]), _c(VContainer, {
    attrs: {
      "fluid": ""
    }
  }, [_c(VRow, [_c(VCol, {
    attrs: {
      "cols": "5.5"
    }
  }, [_c(VCard, {
    staticStyle: {
      "border": "1px solid #757575"
    },
    attrs: {
      "elevation": "0"
    }
  }, [_c(VCardText, [_c('div', [_c('p', [_c(VIcon, [_vm._v("mdi-calendar-start-outline")]), _vm._v(" " + _vm._s(_vm.dateTimeService.getGermanDateFormatWithoutTime(this.currentDraggedEvent.start)) + " ")], 1), !this.currentDraggedEvent.wholeday ? _c('p', [_c(VIcon, [_vm._v("mdi-clock-start")]), _vm._v(" " + _vm._s(_vm.dateTimeService.getTimeStringFromDateObject(new Date(this.currentDraggedEvent.start))) + " ")], 1) : _vm._e()]), _c('div', [_c('p', [_c(VIcon, [_vm._v("mdi-calendar-end-outline")]), _vm._v(" " + _vm._s(_vm.dateTimeService.getGermanDateFormatWithoutTime(this.currentDraggedEvent.end)) + " ")], 1), !this.currentDraggedEvent.wholeday ? _c('p', [_c(VIcon, [_vm._v("mdi-clock-end")]), _vm._v(" " + _vm._s(_vm.dateTimeService.getTimeStringFromDateObject(new Date(this.currentDraggedEvent.end))) + " ")], 1) : _vm._e(), _c('p', [_c(VIcon, [_vm._v("mdi-texture-box")]), _vm._v(" " + _vm._s(this.currentDraggedEvent.scope.name) + " ")], 1), _c('p', [_c(VIcon, [_vm._v("mdi-map-marker")]), _vm._v(" " + _vm._s(this.currentDraggedEvent.location.name) + " ")], 1)])])], 1)], 1), _c(VCol, {
    staticStyle: {
      "display": "flex",
      "justify-content": "center",
      "align-items": "center"
    },
    attrs: {
      "cols": "1"
    }
  }, [_c(VIcon, {
    attrs: {
      "size": "48"
    }
  }, [_vm._v("mdi-chevron-double-right")])], 1), _c(VCol, {
    attrs: {
      "cols": "5.5"
    }
  }, [_c(VCard, {
    staticStyle: {
      "border": "1px solid #757575"
    },
    attrs: {
      "elevation": "0"
    }
  }, [_c(VCardText, [_c('div', [_c('p', [_c(VIcon, [_vm._v("mdi-calendar-start-outline")]), _vm._v(" " + _vm._s(_vm.dateTimeService.getGermanDateFormatWithoutTime(this.appointment.start)) + " ")], 1), !this.appointment.wholeday ? _c('p', [_c(VIcon, [_vm._v("mdi-clock-start")]), _vm._v(" " + _vm._s(_vm.dateTimeService.getTimeStringFromDateObject(new Date(this.appointment.start))) + " ")], 1) : _vm._e()]), _c('div', [_c('p', [_c(VIcon, [_vm._v("mdi-calendar-end-outline")]), _vm._v(" " + _vm._s(_vm.dateTimeService.getGermanDateFormatWithoutTime(this.appointment.end)) + " ")], 1), !this.appointment.wholeday ? _c('p', [_c(VIcon, [_vm._v("mdi-clock-end")]), _vm._v(" " + _vm._s(_vm.dateTimeService.getTimeStringFromDateObject(new Date(this.appointment.end))) + " ")], 1) : _vm._e(), _c('p', [_c(VIcon, [_vm._v("mdi-texture-box")]), _vm._v(" " + _vm._s(this.appointment.scope.name) + " ")], 1), _c('p', [_c(VIcon, [_vm._v("mdi-map-marker")]), _vm._v(" " + _vm._s(this.appointment.location.name) + " ")], 1)])])], 1)], 1)], 1)], 1)]], 2), _c(VCardActions, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "color": "error",
      "depressed": ""
    },
    on: {
      "click": function ($event) {
        _vm.moveAppointmentModal = false;
        _vm.undo();
      }
    }
  }, [_vm._v("Änderung verwerfen")]), _c(VBtn, {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.moveAppointmentModal = false;
        _vm.save();
      }
    }
  }, [_vm._v("Änderung übernehmen")])], 1)], 1), _c('WarningDialog', {
    ref: "warning",
    on: {
      "warningActionPerformed": _vm.warningActionPerformed
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };