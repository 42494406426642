import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VDataTable, {
    staticClass: "datatable-row-pointer",
    attrs: {
      "headers": _vm.headers,
      "search": _vm.search,
      "items": _vm.items
    },
    on: {
      "click:row": _vm.openDetailView
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function () {
        return [_c(VToolbar, {
          attrs: {
            "flat": "",
            "color": "white"
          }
        }, [_c(VTextField, {
          staticClass: "mt-8",
          attrs: {
            "outlined": "",
            "dense": "",
            "append-icon": "mdi-magnify",
            "label": "Suche"
          },
          model: {
            value: _vm.search,
            callback: function ($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        }), _c('LBAButton', {
          staticClass: "mt-1 ml-5",
          attrs: {
            "buttonType": 'primary'
          },
          on: {
            "click": function ($event) {
              return _vm.addTask();
            }
          },
          scopedSlots: _vm._u([{
            key: "content",
            fn: function () {
              return [_c(VIcon, {
                staticClass: "mr-2"
              }, [_vm._v("mdi-plus ")]), _vm._v("Neue Aufgabe ")];
            },
            proxy: true
          }])
        })], 1), _c(VDivider, {
          staticClass: "mt-2"
        })];
      },
      proxy: true
    }, {
      key: "item.lfdnr",
      fn: function ({
        item
      }) {
        return [_c(VChip, {
          attrs: {
            "color": "primary"
          }
        }, [_vm._v("#" + _vm._s(item.lfdnr))])];
      }
    }, {
      key: "item.status",
      fn: function ({
        item
      }) {
        return [_c(VChip, [item.status.done == false ? _c(VIcon, {
          attrs: {
            "left": ""
          }
        }, [_vm._v(" mdi-checkbox-blank-circle-outline ")]) : _vm._e(), item.status.done == true ? _c(VIcon, {
          attrs: {
            "left": "",
            "color": "primary"
          }
        }, [_vm._v(" mdi-check-circle-outline ")]) : _vm._e(), _vm._v(" " + _vm._s(item.status.description) + " ")], 1)];
      }
    }, {
      key: "item.dueDate",
      fn: function ({
        item
      }) {
        return [item.dueDate != null ? _c(VChip, [_c(VIcon, {
          attrs: {
            "left": "",
            "color": _vm.dueDateIconColor(item)
          }
        }, [_vm._v(_vm._s(_vm.dateTimeService.isBeforeToday(item.dueDate) ? 'mdi-calendar-today' : 'mdi-calendar'))]), _vm._v(" " + _vm._s(_vm.dateTimeService.getGermanDateFormatWithoutTime(item.dueDate)) + " ")], 1) : _c(VChip, [_c(VIcon, {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-calendar-blank")]), _vm._v(" Kein Fälligkeitsdatum ")], 1)];
      }
    }, {
      key: "item.creationDate",
      fn: function ({
        item
      }) {
        return [_c(VChip, [_c(VIcon, {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-clock")]), _vm._v(" " + _vm._s(_vm.dateTimeService.getGermanDateFormat(item.creationDate)) + " ")], 1)];
      }
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };