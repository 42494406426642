import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VDialog, {
    attrs: {
      "scrollable": "",
      "max-width": "1000"
    },
    model: {
      value: _vm.appointmentSearch,
      callback: function ($$v) {
        _vm.appointmentSearch = $$v;
      },
      expression: "appointmentSearch"
    }
  }, [_c(VCard, [_c(VCardTitle, {
    staticClass: "mx-0 px-0 my-0 py-0"
  }, [_c(VToolbar, {
    staticClass: "mx-0 px-0 my-0 py-0",
    attrs: {
      "color": "primary",
      "fixed": "",
      "top": "",
      "dark": "",
      "flat": ""
    }
  }, [_c(VIcon, {
    on: {
      "click": function ($event) {
        _vm.appointmentSearch = false;
      }
    }
  }, [_vm._v("mdi-close")]), _c(VToolbarTitle, {
    staticClass: "flex text-center"
  }, [_vm._v("Terminsuche")])], 1)], 1), _c(VCardText, {
    staticClass: "mx-0 px-0 my-0 py-0"
  }, [_c(VSimpleTable, [_c('tbody', [_c('tr', {
    staticClass: "mt-2"
  }, [_c('td', {
    attrs: {
      "colspan": "3"
    }
  }, [_c(VTooltip, {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on
      }) {
        return [_c(VTextField, _vm._g({
          staticClass: "pa-3",
          attrs: {
            "append-icon": "mdi-magnify",
            "label": "Textsuche",
            "hide-details": ""
          },
          on: {
            "keyup": function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
              return _vm.searchAppointments.apply(null, arguments);
            }
          },
          model: {
            value: _vm.searchText,
            callback: function ($$v) {
              _vm.searchText = $$v;
            },
            expression: "searchText"
          }
        }, on))];
      }
    }])
  }, [_c('span', [_vm._v("Suche nach Betreff, Adressen, Objekte und Ressourcen")])])], 1)]), _c(VDataTable, {
    ref: "vuetable",
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.appointments,
      "items-per-page": 10
    },
    scopedSlots: _vm._u([{
      key: "no-data",
      fn: function () {
        return [_vm._v(" Keine Daten gefunden. ")];
      },
      proxy: true
    }, {
      key: "item.actions",
      fn: function ({
        item
      }) {
        return [_c(VBtn, {
          staticClass: "mr-2 col-xl-5",
          attrs: {
            "fab": "",
            "small": "",
            "text": "",
            "outlined": "",
            "elevation": "0"
          },
          on: {
            "click": function ($event) {
              return _vm.setToDate(item);
            }
          }
        }, [_c(VIcon, [_vm._v("mdi-calendar-cursor-outline")])], 1), _c(VBtn, {
          staticClass: "mr-2 col-xl-5",
          attrs: {
            "fab": "",
            "small": "",
            "text": "",
            "outlined": "",
            "elevation": "0"
          },
          on: {
            "click": function ($event) {
              return _vm.editAppointment(item);
            }
          }
        }, [_c(VIcon, {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" mdi-pencil ")])], 1), _c(VBtn, {
          staticClass: "col-xl-5",
          attrs: {
            "fab": "",
            "small": "",
            "text": "",
            "elevation": "0",
            "outlined": "",
            "color": "red"
          },
          on: {
            "click": function ($event) {
              return _vm.tryDeleteAppointment(item);
            }
          }
        }, [_c(VIcon, {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" mdi-delete ")])], 1)];
      }
    }])
  })], 1)])], 1), _c(VCardActions, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "color": "red darken-1",
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.appointmentSearch = false;
      }
    }
  }, [_vm._v("Abbrechen")])], 1)], 1), _c('Loading', {
    ref: "loading"
  }), _c('YouSure', {
    ref: "sureToDelete",
    on: {
      "actionPerformed": _vm.deleteAppointment
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };